const sections = {
  video: {
    element: document.querySelector('section.video'),
    offset: document.querySelector('section.video').offsetTop,
  },
  sections: {
    element: document.querySelector('section.sections'),
    offset: document.querySelector('section.sections').offsetTop,
  },
  explica: {
    elements: document.querySelectorAll('.explica'),

  },
  menu: {
    element: document.querySelector('nav.menu'),
  }
}

function reveal() {
  var reveals = document.querySelectorAll('.reveal');
  var windowHeight, elementTop;

  for (var i=0; i < reveals.length; i++) {
    windowHeight = window.innerHeight;
    elementTop = reveals[i].getBoundingClientRect().top;

    if (elementTop < windowHeight/1.5) {
      reveals[i].classList.add('active');
    } else {
      reveals[i].classList.remove('active');
    }
  }
}

window.addEventListener('scroll', reveal);

function fix() {

  if(window.pageYOffset >= sections.sections.offset) {
    sections.menu.element.style.opacity = 1;
  } else {
    sections.menu.element.style.opacity = 0;
  }
}

window.addEventListener('scroll', fix);

